<template>
  <div
    class="w-full border-t-black-100 border-t flex flex-col gap-2 items-center justify-center py-5 text-center"
  >
    <p class="text-black-500 text-sm font-medium">
      Copyright © 2024 - Yann MALANDA
    </p>
    <p class="text-black-400 text-xs">Onsyvoit v1.0.1</p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
